.login-page-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px) !important;
}
.login-page-login-paper {
  padding: 20px 30px 20px 30px;
  min-width: 400px;
}

.login-page-login-form-header-text {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
.login-page-login-form-header-helpertext {
  font-size: 12px;
  font-weight: 600;
  color: #6e6e6e;
  margin-bottom: 50px;
}

.login-page-login-form-input-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}

.login-page-login-form-input-password {
  width: 100%;
}

.login-page-login-form-login-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.login-page-login-button {
  display: flex;
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.login-error-section {
  margin-top: 15px;
  font-size: 12px;
  color: #d40f1a;
  font-weight: 600;
}
