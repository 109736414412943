.container {
  background-color: #d40f1a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-image {
  width: 50%;
  height: 60px;
}
