.preview-container {
  padding: calc(var(--gap) * 2);
  background-color: #000 !important;
  position: relative;
}

.preview-container h2 {
  color: #fff;
}

.preview-container .logotype {
  background: #fff;
  border-radius: 4px;
  padding: 4px;
  align-self: center;
}

.preview-container .message {
  font-family: SF Pro;
  position: absolute;
  display: flex;
  top: 300px;
  left: 10%;
  width: 75%;
  border-radius: 12px;
  padding: var(--gap);
  background: #d6d6d6;
  opacity: 0.94;
  gap: var(--gap);
  color: #000;
}

.preview-container .title {
  font-size: 16px;
  line-height: 1;
  margin: 0 0 6px 0;
}

.preview-container .body {
  margin: 0;
}

.preview-container .now {
  position: absolute;
  right: var(--gap);
  font-size: 12px;
  color: #3c3c3c;
}
