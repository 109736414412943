.important {
  color: #d40f1a;
}
.notification-page-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  margin-top: 50px;
  gap: calc(var(--gap) * 2);
}
.notification-page-form-container {
  padding: calc(var(--gap) * 2);
  width: 600px;
}

.notification-page-form-field-wrapper {
  margin-bottom: 20px;
}

.notification-page-form-select-wrapper {
  width: 38%;
  margin-top: 33px;
}

.notification-page-article-container {
  margin-top: 30px;
  min-width: 800px;
  padding: 20px 30px 20px 30px;
}
.notification-page-iframe {
  width: 380px;
  height: 300px;
  border: none;
}

.topic-wrapper {
  display: flex;
  margin-top: var(--gap);
  flex-direction: column;
}

.topic-text {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.topic-select-wrapper {
  width: 50%;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
}

.button {
  width: 120px;
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

.cancel-button {
  background-color: #727273;
  color: #fff;
}

.send-button {
  background-color: black;
  color: #fff;
}

.form-error {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  color: #d40f1a;
  font-weight: 600;
  font-size: 14px;
}
.notification-dialog-content {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-page-active-count {
  margin-top: 15px;
  margin-left: 2px;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
